.lines-list-h2 {
    margin-top: 2.5rem;
    text-align: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.LinesList {
    /* min-height: 100%; */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
}
.LinesList .list-container {
    width: 100%;
    text-align: center;
}