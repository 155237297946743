
.AdminLineCard {
    direction: rtl;
    margin: 1rem 5vw 1rem 5vw;
    padding: 1rem;
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    font-size: large;
    text-align: center;
    transition: all 300ms;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    background-color: #f3f3f3;
    background-image: linear-gradient(to right, #f3f3f3, rgb(218, 224, 255) 70%, #f3f3f3);
    transition: background-position 1s ease;
    background-size: 40%;
    background-repeat: no-repeat;
}

.AdminLineCard:hover {
    background-position: right;
}

.AdminLineCard * {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.AdminLineCard h3 {
    width: min-content;
    font-weight: 500;
}

.AdminLineCard h4 {
    margin: 0 0 20px 0;
    font-weight: 500;
}

.AdminLineCard h5 {
    margin: 0;
}

.AdminLineCard .lineDetails span {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-weight: 350;
}
