.App {
    direction: rtl;
    height: 100%;
    min-height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* background-color: #fff; */
}

.gradient-bg {
    background: linear-gradient(-45deg, #23d5ab, #00920f, #52ff42, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.nav .smoothscroll {
    font-size: large
}

.description {
    min-height: 20vh;
    line-height: 3rem !important;
    font-family: sans-serif !important;
    color: #fff;
    text-shadow: 2px 2px 2px black;
    font-size: 2rem !important;
}

.MuiPickersLayout-actionBar {
    display: none !important;
}
.MuiTypography-overline {
    display: none;
}

#header-bg {
    height: 40vh !important;
    margin-top: 5vh;
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
}

.icon-down-circle {
    margin-right: 0;
}

.LineCard {
    cursor: pointer;
    background-color: #ebf6fe99;
    padding: 7px 1rem;
    text-align: center;
    max-width: 300px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    border-radius: 10px;
    transition: transform 0.5s ease, background-color 0.3s ease;
}

.LineCard:hover {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.LineCard p {
    border-bottom: 2px solid rgb(8, 226, 8);
    margin-bottom: 1rem;
    font-family: 'opensans-bold';
    color: rgba(0, 0, 0, 0.741);
}

.linesList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 1rem;
    max-width: 70vw;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 10px;
}

.lineDetails {
    margin: 5px 0 0 0;
    text-align: center;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.NotFoundPage {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 60px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: rgba(0, 0, 0, 0.751);
    background: #fff;
}

.NotFoundPage h3 {
    margin: 0;
}

.NotFoundPage a {
    font-size: 30px;
    text-decoration: underline;
}

.Login {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100%;
    width: 100%;
    direction: rtl;
}

#login-btn {
    position: absolute;
    top: 70px;
    right: 20px;
    font-size: x-large;
    margin: 5px;
    color: #fff;
    cursor: pointer;
}

.ModalImage {
    cursor: zoom-in;
    margin: auto;
    width: fit-content;
}

.ModalImage>img {
    display: block;
    width: 100%;
    /* max-width: 100%; */
    /* min-width: max-content; */
    height: 230px;
    max-width: 70vw;
    border-radius: 7px;
}

.widget {
    margin-right: 1rem;
}

.lineCardBox #lineCardDateTime {
    display: flex;
    justify-content: space-evenly;
}

.form-actions-div {
    margin: 1rem;
    display: flex;
    justify-content: center;
}