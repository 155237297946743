.SettingsPage {
    /* background: #fff; */
    height: 100%;
    min-width: 200px;
    margin: 0;
    padding: 1rem;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.SettingsPage>* {
    margin: 1rem 1rem 1rem 1rem;
    max-width: 750px;
}

.SettingsPage .description-line {
    display: flex;
    align-items: center;
    width: 100%;

}

.SettingsPage .imagesEditor img {
    margin: 0;
    padding: 0;
    border-radius: 8px;
    -webkit-user-drag: none;
    -webkit-touch-callout: none;
    user-select: none;
}

.SettingsPage .portfolioEdit {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
}

.SettingsPage .portfolioEdit>* {
    margin: 1rem;
}