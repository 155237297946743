.HistoryPage {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
}

.HistoryPage .list-container {
    width: 100%;
    text-align: center;
}