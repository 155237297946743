.CopyToClipboard {
    margin: 1rem;
    padding: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    /* width: 100%; */
    max-width: 100vw;
    justify-content: space-around;
}

.CopyToClipboard h6 {
    padding: 0.5rem;
    border-radius: 0 5px 5px 0;
    white-space: nowrap;
    box-shadow:
        rgba(0, 0, 0, 0.2) 0px 3px 6px,
        /* General shadow for depth */
        rgba(0, 0, 0, 0.15) 0px 6px 12px,
        /* Slightly larger outer shadow */
        rgba(166, 164, 164, 0.498) 0px -2px 4px inset;
    /* Highlight effect at the top */
    ;
}

.CopyToClipboard a {
    direction: ltr;
    padding: 0.7rem;
    border: 1px solid rgba(0, 0, 0, 0.138);
    border-radius: 5px 0 0 5px;
    box-shadow: rgba(50, 50, 93, 0.049) 0px 20px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 30px -18px inset;
}