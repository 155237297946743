.AdminLayout {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    /* grid-template-rows: 15% 70% 15%; */
}

.AdminLayout * {
    box-sizing: border-box;
}

.AdminLayout>footer {
    /* direction: ltr; */
    /* padding: 5px; */
    /* text-align: center; */
    background-color: #000524;
    grid-column: span 6;
    position: unset !important;
    color: #fff !important;
    /* background-color: rgba(158, 252, 221, 0.747); */
    /* color: #fff; */
}

.AdminLayout>footer a,
.AdminLayout>footer a:visited {
    color: #ffffff !important;
}

.AdminLayout>footer a:hover,
.AdminLayout>footer a:focus {
    color: #828282 !important;
}

.AdminLayout>header {
    grid-column: span 6;
    height: 8.5vh;
}

.AdminLayout>main {
    height: 100%;
    grid-column: span 6;
}

@media only screen and (max-width: 800px) {

    .AdminLayout>header {
        grid-column: span 6;
        height: 6vh;
    }
}