.ScheduleAndVaca {
    height: 100%;
    margin: 0;
    padding: 1rem 0;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ScheduleAndVaca .adminWorkingDays {
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: auto;
}

.ScheduleAndVaca>* {
    margin: 1rem 1rem 3rem 1rem;
}

.ScheduleAndVaca .vacation-line {
    display: flex;
    align-items: center;
    width: 100%;

}

.ScheduleAndVaca .css-i5q14k-MuiDayCalendar-header {
    flex-direction: row-reverse !important;
}

.ScheduleAndVaca .css-flbe84-MuiDayCalendar-weekContainer {
    flex-direction: row-reverse !important;
}

.ScheduleAndVaca .css-1eurbeq-MuiPickersToolbar-root-MuiDatePickerToolbar-root {
    display: none !important;
}

.ScheduleAndVaca .dayDiv {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 1rem;
}