.AccountPage {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
}

.AccountPage .uploadLogoDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 1rem;
}

.AccountPage>* {
    margin: 1rem 1rem 3rem 1rem;
    max-width: 700px;
}
.AccountPage .userDetails{
    margin: 2rem;
    display: flex;
    align-items: center;
}
@media (max-width: 600px) {
    .userDetails {
      flex-direction: column; /* Stack items vertically on small screens */
    }
}