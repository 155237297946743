.LinesInfo .sumDiv {
    color: rgba(0, 0, 0, 0.807);
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.LinesInfo .sumDiv h1 {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 500;
}
.LinesInfo .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3;
  }
